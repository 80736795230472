<h3 style="font-size: 18px">{{ label }}</h3>

<form *ngIf="form && label" [formGroup]="form">
  <mat-checkbox
    color="primary"
    [formControlName]="label"
    style="margin: 0.5em 0"
  >
    {{ label }}
  </mat-checkbox>
</form>
