import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DireccionComponent } from "./direccion/direccion.component";
import { MaterialModule } from "../material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    FlexLayoutModule,
  ],
  declarations: [DireccionComponent],
  exports: [DireccionComponent],
})
export class DireccionModule {}
