import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ICoordenadas } from 'modelos/src';
import { MapaCrearCoordenadaComponent } from 'src/app/auxiliares/mapa-crear-coordenada/mapa-crear-coordenada.component';

@Component({
  standalone: true,
  imports: [CommonModule, MapaCrearCoordenadaComponent],
  selector: 'app-ubicacion',
  templateUrl: './ubicacion.component.html',
  styleUrls: ['./ubicacion.component.scss'],
})
export class UbicacionComponent implements OnInit {
  @Input() label?: string;
  @Input() form?: FormGroup;

  constructor() {}

  public onUbicacionChange(u: ICoordenadas) {
    // this.coordenada = u;
    this.form?.patchValue({ [this.label!]: u });
  }

  ngOnInit(): void {}
}
