import { Component } from '@angular/core';
import { NOMBRE_APP } from '../environments/cliente';
import { NOMBRE_APP_TO_CLIENTE } from '../environments/contantes';
import { HelperService } from './auxiliares/servicios/helper.service';
import { EnvsService } from './auxiliares/servicios/envs.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private platform = Capacitor.getPlatform() as 'android' | 'ios' | 'web';

  constructor(private envsService: EnvsService) {
    this.loadCliente();
  }

  public atrasDom() {
    document.body.classList.remove('qrscanner'); // remove the qrscanner from the body
  }

  private loadCliente() {
    const cliente = NOMBRE_APP_TO_CLIENTE[NOMBRE_APP];
    if (cliente) {
      const fileNameCliente =
        HelperService.nombreClienteToClienteFilename(cliente);
      this.envsService.loadCliente(fileNameCliente, this.platform);
    }
  }
}
