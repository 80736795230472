import { Injectable } from '@angular/core';
import { NOMBRE_APP } from '../environments/cliente';

@Injectable({
  providedIn: 'root',
})
export class NombreAppService {
  private _nombreAPP = NOMBRE_APP;

  set nombreAPP(nombre: string) {
    localStorage.setItem('nombreApp', nombre);
    this._nombreAPP = nombre;
  }

  get nombreAPP() {
    return this._nombreAPP;
  }

  constructor() {
    if (NOMBRE_APP === 'Botón de Pánico Municipal') {
      const stored = localStorage.getItem('nombreApp');
      this._nombreAPP = stored || NOMBRE_APP;
    }
  }
}
