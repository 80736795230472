import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { StoreService } from '../../entidades/login/store.service';
import { VERSION, apiBoton } from '../../../environments/environment';
import { Capacitor } from '@capacitor/core';
import { NombreAppService } from '../../nombreApp.service';
import { Router } from '@angular/router';
import { IToken } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private platform = Capacitor.getPlatform();

  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private nombreAppService: NombreAppService,
    private router: Router,
  ) {}

  private async logout(): Promise<void> {
    await this.storeService.removeLoginInfo();
    this.router.navigateByUrl('/login');
  }

  private async refreshToken(): Promise<void> {
    const refresh_token = await this.storeService.getRefreshToken();
    if (!refresh_token) {
      return;
    }
    const resp = await firstValueFrom(this._refreshToken(refresh_token));
    await this.storeService.setLoginInfo(resp);
  }

  public _refreshToken(refresh_token: string): Observable<IToken> {
    const url = `${apiBoton}/auth/refresh_token`;

    const headers = new HttpHeaders({
      appVersion: VERSION,
      app: this.nombreAppService.nombreAPP,
      appType: this.platform,
    });

    const body = {
      refresh_token,
      grant_type: 'refresh_token',
    };

    return this.http.post<IToken>(url, body, { headers });
  }

  public get(ruta: string, noCache = false, params?: any): Observable<any> {
    return new Observable((observer) => {
      this._get(ruta, noCache, params)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
        })
        .catch(async (err) => {
          if (
            err.error?.message === 'Usuario deshabilitado' ||
            err.error?.message === 'Cliente deshabilitado'
          ) {
            this.logout();
            observer.complete();
          } else if (
            err.error?.message === 'Invalid token: access token has expired' &&
            (await this.storeService.getRefreshToken())
          ) {
            console.log('refreshing token');
            this.refreshToken()
              .then(() => {
                this._get(ruta, noCache, params)
                  .then((resp) => {
                    observer.next(resp);
                    observer.complete();
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public post(ruta: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this._post(ruta, body)
        .then((resp) => {
          observer.next(resp);
        })
        .catch(async (err) => {
          if (
            err.error?.message === 'Usuario deshabilitado' ||
            err.error?.message === 'Cliente deshabilitado'
          ) {
            this.logout();
            observer.complete();
          } else if (
            err.error?.message === 'Invalid token: access token has expired' &&
            (await this.storeService.getRefreshToken())
          ) {
            console.log('refreshing token');
            this.refreshToken()
              .then(() => {
                this._post(ruta, body)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public put(ruta: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this._put(ruta, body)
        .then((resp) => {
          observer.next(resp);
        })
        .catch(async (err) => {
          if (
            err.error?.message === 'Usuario deshabilitado' ||
            err.error?.message === 'Cliente deshabilitado'
          ) {
            this.logout();
            observer.complete();
          } else if (
            err.error?.message === 'Invalid token: access token has expired' &&
            (await this.storeService.getRefreshToken())
          ) {
            console.log('refreshing token');
            this.refreshToken()
              .then(() => {
                this._put(ruta, body)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public delete(ruta: string): Observable<any> {
    return new Observable((observer) => {
      this._delete(ruta)
        .then((resp) => {
          observer.next(resp);
        })
        .catch(async (err) => {
          if (
            err.error?.message === 'Usuario deshabilitado' ||
            err.error?.message === 'Cliente deshabilitado'
          ) {
            this.logout();
            observer.complete();
          } else if (
            err.error?.message === 'Invalid token: access token has expired' &&
            (await this.storeService.getRefreshToken())
          ) {
            console.log('refreshing token');
            this.refreshToken()
              .then(() => {
                this._delete(ruta)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  private _get(ruta: string, noCache = false, params?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token = await this.storeService.getToken();

      let headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: this.nombreAppService.nombreAPP,
        appType: this.platform,
      });
      const idCliente = (await this.storeService.getCliente())?._id;
      if (idCliente) {
        headers = headers.set('idCliente', idCliente);
      }
      if (noCache) {
        headers = headers.set('no-cache', 'true');
      }
      const req = this.http.get<any>(ruta, { headers, params });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  private _post(ruta: string, body: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token = await this.storeService.getToken();
      let headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: this.nombreAppService.nombreAPP,
        appType: this.platform,
      });
      const idCliente = (await this.storeService.getCliente())?._id;
      if (idCliente) {
        headers = headers.set('idCliente', idCliente);
      }
      const req = this.http.post<any>(ruta, body, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  private _put(ruta: string, body: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token = await this.storeService.getToken();
      let headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: this.nombreAppService.nombreAPP,
        appType: this.platform,
      });
      const idCliente = (await this.storeService.getCliente())?._id;
      if (idCliente) {
        headers = headers.set('idCliente', idCliente);
      }
      const req = this.http.put<any>(ruta, body, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  private _delete(ruta: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token = await this.storeService.getToken();
      let headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: this.nombreAppService.nombreAPP,
        appType: this.platform,
      });
      const idCliente = (await this.storeService.getCliente())?._id;
      if (idCliente) {
        headers = headers.set('idCliente', idCliente);
      }
      const req = this.http.delete<any>(ruta, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }
}
