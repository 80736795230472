import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../../auxiliares/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss'],
})
export class PersonaComponent implements OnInit {
  @Input() label?: string;
  @Input() form?: FormGroup;

  @Input() individuo?: boolean;

  public personas: IPersona[] = [];
  public nombre?: string;
  public dni?: string;

  constructor() {}

  public agregarPersona() {
    if (this.nombre && this.dni) {
      this.personas.push({ nombre: this.nombre, dni: this.dni });
      this.nombre = '';
      this.dni = '';
      this.form?.patchValue({ [this.label!]: this.personas });
    }
  }

  public onChange() {
    this.personas = [{ nombre: this.nombre || '', dni: this.dni || '' }];
    this.form?.patchValue({ [this.label!]: this.personas });
  }

  ngOnInit(): void {}
}

export interface IPersona {
  nombre: string;
  dni: string;
}
