import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class UpdatesService {
  private platform = Capacitor.getPlatform();

  constructor(private dialog: DialogService) {}

  public async checkUpdates() {
    if (this.platform === 'web') return;

    const appUpdateInfo = await AppUpdate.getAppUpdateInfo();
    if (
      appUpdateInfo.updateAvailability ===
      AppUpdateAvailability.UPDATE_AVAILABLE
    ) {
      const confirm = await this.dialog.confirm(
        'Nueva versión disponible',
        'Hay una nueva versión disponible. ¿Desea actualizarla?',
      );
      if (!confirm) return;

      if (this.platform === 'ios') {
        await this.openAppStore();
      }

      if (this.platform === 'android') {
        if (appUpdateInfo.immediateUpdateAllowed) {
          await this.performImmediateUpdate();
        } else if (appUpdateInfo.flexibleUpdateAllowed) {
          await this.startFlexibleUpdate();
          await this.completeFlexibleUpdate();
        } else {
          await this.openAppStore();
        }
      }
    } else {
      return false;
    }
  }

  private getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return result.currentVersionCode;
    } else {
      return result.currentVersionName;
    }
  };

  private getAvailableAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return result.availableVersionCode;
    } else {
      return result.availableVersionName;
    }
  };

  private openAppStore = async () => {
    await AppUpdate.openAppStore();
  };

  private performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };

  private startFlexibleUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.flexibleUpdateAllowed) {
      await AppUpdate.startFlexibleUpdate();
    }
  };

  private completeFlexibleUpdate = async () => {
    await AppUpdate.completeFlexibleUpdate();
  };
}
