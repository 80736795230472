<!-- Filtro y Columnas -->
<div
  class="filtro-container align-end"
  style="overflow: auto"
  [class.d-none]="!selectorColumnas && !search && !filtros.length"
>
  <!-- Filtro -->
  <app-filtro
    style="width: 100%"
    [search]="search"
    [filtros]="filtros"
    [(query)]="query"
    (queryChange)="filterChange()"
  >
  </app-filtro>
  <!-- Columnas -->
  <app-column-select
    *ngIf="columnas"
    [columnas]="columnas"
    (columnsDefChange)="columnsChange($event)"
    [saveName]="name"
    [selectorColumnas]="selectorColumnas"
  >
  </app-column-select>

  <img
    src="assets/iconos/mas.png"
    alt="crear"
    style="width: 59px; cursor: pointer"
    *ngIf="crear?.mostrar"
    [matTooltip]="crear?.tooltip!"
    (click)="crear?.accion()"
  />

  <img
    src="assets/iconos/export-boton.png"
    alt="exportar"
    style="width: 59px; cursor: pointer"
    *ngIf="exportar?.mostrar"
    [matTooltip]="exportar?.tooltip!"
    (click)="exportar?.accion()"
  />
</div>

<!-- Paginator -->
<div class="paginator-container-top fondo-85" *ngIf="paginatorArriba">
  <app-paginator
    #topPaginator
    [saveName]="name"
    [length]="totalCount"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    [(query)]="query"
    (queryChange)="paginationChange()"
  ></app-paginator>
</div>

<mat-table
  class="responsive fondo-85"
  *ngIf="dataSource"
  [dataSource]="dataSource"
  matSort
  (matSortChange)="sortChange($event)"
>
  <mat-header-row *matHeaderRowDef="columnsDef; sticky: true"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: columnsDef"> </mat-row>
  <ng-container *ngIf="columnas?.[0]?.footer">
    <mat-footer-row *matFooterRowDef="columnsDef"></mat-footer-row>
  </ng-container>

  <!-- Columnas -->
  <ng-container *ngFor="let col of columnas">
    <ng-container
      *ngIf="
        $any(col.header.sort || col.row.field || col.header.label) as colDef
      "
      [matColumnDef]="colDef"
    >
      <!-- Header Sortable -->
      <ng-container *ngIf="col.header.sortable">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ col.header.label }}
        </mat-header-cell>
      </ng-container>
      <!-- Header Not Sortable -->
      <ng-container *ngIf="!col.header.sortable">
        <mat-header-cell *matHeaderCellDef>
          {{ col.header.label }}
        </mat-header-cell>
      </ng-container>

      <!-- Row -->
      <mat-cell *matCellDef="let row">
        <!-- Mobile Label -->
        <span class="mobile-label" *ngIf="col.header.label !== 'Acciones'">
          {{ col.header.label }}
        </span>

        <div>
          <!-- Field Content -->
          <span *ngIf="col.row.field as field">
            <!-- Prefix -->
            <span *ngIf="field">{{ col.row.prefix }} </span>
            <!-- Si es solo field -->
            <ng-container
              *ngIf="
                !col.row.parse &&
                !col.row.icono &&
                !col.row.html &&
                !col.row.links &&
                !col.row.acciones &&
                !col.row.accionesGrouped
              "
            >
              <ng-container *ngIf="row[field] as value">
                <span
                  [class]="col?.row?.claseCss?.(row)"
                  [matTooltip]="$any(col?.row?.tooltip?.(row))"
                >
                  {{
                    value | dynamicPipe: col.row.pipe?.pipe : col.row.pipe?.args
                  }}
                </span>
              </ng-container>
              <!-- No Data -->
              <ng-container *ngIf="!row[field] && col?.row?.noData">
                <span class="no-info">
                  {{ col?.row?.noData }}
                </span>
              </ng-container>
            </ng-container>
            <!-- Suffix -->
            <span *ngIf="field"> {{ col.row.suffix }}</span>
          </span>
          <!-- Field Parsed -->
          <span *ngIf="col.row.parse as parse">
            <!-- Prefix -->
            <span *ngIf="parse(row) !== undefined">{{ col.row.prefix }} </span>
            <span
              [class]="col?.row?.claseCss?.(row)"
              [matTooltip]="$any(col?.row?.tooltip?.(row))"
            >
              {{
                parse(row)
                  | dynamicPipe: col.row.pipe?.pipe : col.row.pipe?.args
              }}
            </span>
            <!-- No Data -->
            <ng-container *ngIf="parse(row) === undefined && col?.row?.noData">
              <span class="no-info">
                {{ col?.row?.noData }}
              </span>
            </ng-container>
            <!-- Suffix -->
            <span *ngIf="parse(row) !== undefined"> {{ col.row.suffix }}</span>
          </span>
          <!-- Field icono -->
          <span *ngIf="col.row.icono as fnIcono">
            <span *ngIf="fnIcono(row) as icono" style="margin: 0.5em">
              <mat-icon
                *ngIf="icono.tipo !== 'img'"
                [style.color]="icono.color"
                [matTooltip]="icono.tooltip!"
              >
                {{ icono.icon }}
              </mat-icon>
              <img
                *ngIf="icono.tipo === 'img'"
                [matTooltip]="icono.tooltip!"
                [src]="icono.icon"
                alt="icono"
                style="width: 30px"
              />
            </span>
          </span>
          <!-- Field link -->
          <span *ngIf="col.row.links as fnLinks">
            <span *ngIf="fnLinks(row) as links" style="margin: 0.5em">
              <ng-container *ngFor="let l of links; trackBy: trackBy">
                <p
                  [class]="l.class"
                  [routerLink]="l.route"
                  [matTooltip]="$any(l.tooltip)"
                  (click)="l.click?.()"
                >
                  {{ l.link }}
                </p>
              </ng-container>
            </span>
          </span>
          <!-- Field HTML -->
          <div
            *ngIf="col.row.html as html2"
            [innerHTML]="html(html2(row))"
          ></div>
          <!-- Field acciones -->
          <span *ngIf="col.row.acciones as acciones" class="acciones-container">
            <ng-container *ngFor="let accion of acciones">
              @if (
                accion?.tipo !== 'img' &&
                !accion?.oculta?.(row) &&
                !accion.texto
              ) {
                <button
                  class="grid-item"
                  mat-mini-fab
                  [color]="accion.color"
                  [matTooltip]="accion.tooltip"
                  [disabled]="accion?.disabled?.(row)"
                  (click)="accion.click(row)"
                >
                  <mat-icon>{{ accion.icon }}</mat-icon>
                </button>
              }
              @if (
                accion?.tipo !== 'img' && !accion?.oculta?.(row) && accion.texto
              ) {
                <button
                  class="grid-item"
                  mat-raised-button
                  [color]="accion.color"
                  [matTooltip]="accion.tooltip"
                  [disabled]="accion?.disabled?.(row)"
                  (click)="accion.click(row)"
                >
                  <mat-icon>{{ accion.icon }}</mat-icon>
                  <span>{{ accion.texto }}</span>
                </button>
              }
              @if (accion?.tipo === 'img' && !accion?.oculta?.(row)) {
                <img
                  class="grid-item img-accion"
                  [matTooltip]="accion.tooltip"
                  [src]="accion.icon"
                  alt="icono"
                  style="cursor: pointer"
                  (click)="accion.click(row)"
                />
              }
            </ng-container>
          </span>
          <!-- Field acciones agrupadas -->
          <span *ngIf="col.row.accionesGrouped as accionesGrouped">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let accion of accionesGrouped">
                <button
                  *ngIf="!accion?.oculta?.(row)"
                  mat-menu-item
                  [disabled]="accion?.disabled?.(row)"
                  (click)="accion.click(row)"
                >
                  <mat-icon [color]="accion.color">{{ accion.icon }}</mat-icon>
                  <span>{{ accion.tooltip }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </span>
        </div>
      </mat-cell>

      <!-- Footer -->
      <mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="col.footer?.fn as fn">
          <div>
            <!-- Prefix -->
            <span> {{ col.footer?.prefix }} </span>
            <!-- Function -->
            <span>
              {{
                fn(col)
                  | dynamicPipe: col.footer?.pipe?.pipe : col.footer?.pipe?.args
              }}
            </span>
            <!-- Suffix -->
            <span> {{ col.footer?.suffix }} </span>
          </div>
        </ng-container>
      </mat-footer-cell>
    </ng-container>
  </ng-container>
</mat-table>

<div class="paginator-container fondo-85" *ngIf="paginatorAbajo">
  <app-paginator
    #botPaginator
    [saveName]="name"
    [length]="totalCount"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    [(query)]="query"
    (queryChange)="paginationChange()"
  ></app-paginator>
</div>
