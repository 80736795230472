import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICoordenadas } from 'modelos/src';
import { Feature, Map, View } from 'ol';
import { OpenLayersService } from '../servicios/openLayers.service';
import { Point } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Modify } from 'ol/interaction';

@Component({
  selector: 'app-mapa-crear-coordenada',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mapa-crear-coordenada.component.html',
  styleUrl: './mapa-crear-coordenada.component.scss',
})
export class MapaCrearCoordenadaComponent implements OnInit {
  @Input() coordenada?: ICoordenadas;
  @Output() coordenadaChange = new EventEmitter<ICoordenadas>();

  @Input() zoom = 16;

  /// OPENLAYERS
  public map?: Map;
  public tileLayer = OpenLayersService.mapTile();
  // Icon
  public pinLayer = OpenLayersService.pinVectorLayer();

  constructor(private openLayers: OpenLayersService) {}

  private async initMap() {
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'mapa-coordenada',
      controls: [],
      layers: [this.tileLayer, this.pinLayer],
      view: new View({
        center: await this.center(),
        zoom: this.zoom,
      }),
    });

    this.handleIcon();
    this.handleModify();
  }

  private async center() {
    if (this.coordenada) {
      return OpenLayersService.lonLatToCoordinate(
        this.coordenada.lng,
        this.coordenada.lat,
      );
    } else {
      return await this.openLayers.getCurrentPositionCapacitor();
    }
  }

  private async handleIcon() {
    /// Agrego el pin
    const source = this.pinLayer.getSource();
    if (!source) return;
    source.clear();
    const f = new Feature({
      geometry: new Point(await this.center()),
    });
    f.setId('pin');
    source.addFeature(f);
    this.panTo(f.getGeometry().getCoordinates());
  }

  private handleModify() {
    const source = this.pinLayer.getSource();
    if (!source) return;
    /// Interacción
    const modify = new Modify({
      hitDetection: this.pinLayer,
      source: source,
    });
    const emiter = this.coordenadaChange;
    modify.on(['modifyend'], function (evt) {
      const p = source.getFeatureById('pin').getGeometry() as Point;
      if (!p) return;
      const c = p.getCoordinates();
      emiter.emit(OpenLayersService.coordinateToCoordenada(c));
    });
    this.map.addInteraction(modify);
  }

  private panTo(c: Coordinate) {
    if (!this.map) return;
    this.map.getView().setCenter(c);
  }

  async ngOnInit() {
    await this.initMap();
    this.coordenadaChange.emit(
      OpenLayersService.coordinateToCoordenada(await this.center()),
    );
  }
}
