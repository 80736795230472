import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../../auxiliares/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  selector: 'app-fecha',
  templateUrl: './fecha.component.html',
  styleUrls: ['./fecha.component.scss'],
})
export class FechaComponent implements OnInit {
  @Input() form?: FormGroup;
  @Input() label?: string;

  public fechaMin = new Date();
  public fecha = new Date();
  public hora = new Date().getHours();

  constructor() {}

  public onChange() {
    const date = new Date(this.fecha);
    date.setHours(this.hora, 0, 0, 0);
    this.form?.patchValue({ [this.label!]: date });
  }

  ngOnInit(): void {
    this.form?.patchValue({ [this.label!]: this.fecha });
  }
}
