import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule],
  selector: 'app-booleano',
  templateUrl: './booleano.component.html',
  styleUrls: ['./booleano.component.scss'],
})
export class BooleanoComponent implements OnInit {
  @Input() label?: string;
  @Input() form?: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
