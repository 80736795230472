<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
</ng-container>

<mat-dialog-content style="min-height: 50px">
  <div [innerHTML]="message"></div>
</mat-dialog-content>

<br />

<mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="matDialogRef.close(true)">
    {{ btnOkText }}
  </button>
  <button mat-flat-button (click)="matDialogRef.close(false)">
    {{ btnCancelText }}
  </button>
</mat-dialog-actions>
