export const APPEARANCE: 'outline' | 'fill' = 'outline';

export const MAPS_KEY_WEB = 'AIzaSyDpJVs3PWZ13MRU-UsOlx8gi_pXYVNE7PE';
export const MAPS_KEY_ANDROID = 'AIzaSyDFQuDsjpWnPfRlD-Tqnl8OrxN4tWZnbQw';
export const MAPS_KEY_IOS = 'AIzaSyA5rMOMLlU04gejJQJ5Zw8tJ3UwMjdqeb4';

export const NOMBRE_APP_TO_CLIENTE: { [nombreApp: string]: string } = {
  // ACÁ VAN LOS QUE TIENEN TEMPLATE
  'Barrios Privados': 'Barrios Privados',
  'Brown Previene': 'Almirante Brown',
  'Alarma Comunitaria': 'Avellaneda',
  'Alerta Temprana': 'Santa Cruz',
  'Ezeiza Segura': 'Ezeiza',
  'Hurlingham Segura': 'Hurlingham',
  'Alerta Ciudadana': 'La Matanza',
  'Luján Alerta 24': 'Lujan',
  'Mi Alerta Quilmes': 'Quilmes',
  'Seguridad Lomas': 'Lomas de Zamora',
  // 'Botón de Pánico': 'Botón de Pánico',
  // 'Alerta Magdalena': 'Magdalena',
  // 'Alerta La Costa': 'La Costa',
};

export const CLIENTE_TO_NOMBRE_APP: { [cliente: string]: string } = {
  avellaneda: 'Alarma Comunitaria',
  'la matanza': 'Alerta Ciudadana',
  'santa cruz': 'Alerta Temprana',
  quilmes: 'Mi Alerta Quilmes',
  'almirante brown': 'Brown Previene',
  ezeiza: 'Ezeiza Segura',
  hurlingham: 'Hurlingham Segura',
  chascomus: 'Chascomús Segura',
  'barrios privados': 'Barrios Privados',
  lujan: 'Luján Alerta 24',
  'lomas de zamora': 'Seguridad Lomas',
  'bahía blanca': 'Somos Bahía',
};
