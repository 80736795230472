import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { PermisosComponent } from './permisos/permisos.component';

@NgModule({
  declarations: [PermisosComponent],
  imports: [CommonModule, MaterialModule],
  exports: [PermisosComponent],
})
export class PermisosModule {}
