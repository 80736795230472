import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IConfigVecino } from 'modelos/src';
import { firstValueFrom } from 'rxjs';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { StoreService } from 'src/app/entidades/login/store.service';
import { ReclamosService } from 'src/app/entidades/reclamos/reclamos.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
  selector: 'app-subir-imagen',
  templateUrl: './subir-imagen.component.html',
  styleUrls: ['./subir-imagen.component.scss'],
})
export class SubirImagenComponent implements OnInit {
  public loading = false;
  public url?: string;

  @Input() label?: string;
  @Input() form?: FormGroup;

  public config?: IConfigVecino;
  constructor(
    private service: ReclamosService,
    private store: StoreService,
    private dialogService: DialogService,
  ) {}

  public async setFile(event: Event): Promise<void> {
    this.loading = true;
    const resp = await this.dialogService.confirm(
      'Subir Imagen',
      '¿Está seguro que desea subir la imagen?',
    );
    if (!resp) {
      this.loading = false;
      return;
    }
    const file = (event as any).target?.files?.item(0);
    const nombreImagen = `eventos/${
      this.config?.cliente?.nombre
    }/${new Date().getTime()}.png`;
    const res = await firstValueFrom(
      this.service.subirImagen(file, nombreImagen),
    );
    this.url = res.url;
    this.form?.patchValue({ [this.label!]: this.url });
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.config = await this.store.getConfig();
  }
}
