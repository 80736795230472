<h3 style="font-size: 18px">{{ label }}</h3>

<div class="row-center">
  <mat-form-field style="width: 75%">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [min]="fechaMin"
      [(ngModel)]="fecha"
      (dateChange)="onChange()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field style="width: 25%">
    <mat-label>Hora</mat-label>
    <input
      matInput
      type="number"
      min="0"
      max="23"
      [(ngModel)]="hora"
      (change)="onChange()"
    />
  </mat-form-field>
</div>
