import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICrearErrorCliente, IErrorCliente } from "modelos/src";
import { take } from "rxjs/operators";
import { apiBoton, VERSION } from "../../../environments/environment";
import { NOMBRE_APP } from "../../../environments/cliente";
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: "root",
})
export class ErroresClienteService {
  private platform = Capacitor.getPlatform();

  constructor(private http: HttpClient) {}

  private getStringError(error: any): string {
    try {
      if (typeof error === "string") {
        return error;
      }
      if (typeof error === "object") {
        return JSON.stringify(error);
      }
      if (typeof error === "symbol" || typeof error === "number") {
        return error.toString();
      }
      return "undefined";
    } catch (err) {
      return "No se pudo obtener el error";
    }
  }

  public async crear(error: any): Promise<IErrorCliente | void> {
    try {
      const url = `${apiBoton}/ErroresClientes`;

      const data: ICrearErrorCliente = {
        error: this.getStringError(error),
        userAgent: window.navigator.userAgent,
        app: "Boton",
        versionApp: VERSION,
      };

      const headers = new HttpHeaders({
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: this.platform,
      });

      const reponse = await this.http
        .post<IErrorCliente>(url, data, { headers })
        .pipe(take(1))
        .toPromise();
      return reponse;
    } catch (err) {
      // this.helper.notifError("No se pudo enviar el error");
      // this.helper.notifError(err);
    }
  }
}
