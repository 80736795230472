import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadChildren: () =>
      import('./entidades/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'aplicaciones',
    loadChildren: () =>
      import('./entidades/links/module').then((m) => m.LinksModule),
  },
  {
    path: 'registro',
    loadChildren: () =>
      import('./entidades/registro/module').then((m) => m.RegistroModule),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./entidades/menu/module').then((m) => m.MenuModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
