<h3 style="font-size: 18px">{{ label }}</h3>

<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="15px"
  style="
    text-align: center;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  "
>
  @if (!loading) {
    <button
      mat-mini-fab
      type="button"
      color="primary"
      (click)="fileInput.click()"
      style="width: 40px"
    >
      <mat-icon>upload</mat-icon>
    </button>
  }

  <input
    #fileInput
    matInput
    type="file"
    style="position: fixed; top: -20000px"
    accept="image/*"
    (change)="setFile($event)"
  />
  @if (url) {
    <img [src]="this.url" alt="Sin Imagen" [style.max-width]="'100px'" />
  }
  @if (loading) {
    <mat-spinner color="primary" diameter="24"></mat-spinner>
  }
</div>
