import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

const MAPS_KEY_WEB = 'AIzaSyDpJVs3PWZ13MRU-UsOlx8gi_pXYVNE7PE';
const MAPS_KEY_ANDROID = 'AIzaSyDFQuDsjpWnPfRlD-Tqnl8OrxN4tWZnbQw';
const MAPS_KEY_IOS = 'AIzaSyA5rMOMLlU04gejJQJ5Zw8tJ3UwMjdqeb4';

@Injectable({
  providedIn: 'root',
})
export class EnvsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  private loadStyle(cliente: string) {
    let elem = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (elem) {
      elem.href = `${cliente}.css`;
    } else {
      const head = this.document.getElementsByTagName('head')[0];
      const elem = this.document.createElement('link');
      elem.id = 'client-theme';
      elem.rel = 'stylesheet';
      elem.href = `${cliente}.css`;
      head.appendChild(elem);
    }
  }
  private loadManifest(cliente: string) {
    let elem = this.document.getElementById(
      'client-manifest',
    ) as HTMLLinkElement;
    if (elem) {
      elem.href = `./assets/clientes/${cliente}/manifest.webmanifest`;
    } else {
      const head = this.document.getElementsByTagName('head')[0];
      const elem = this.document.createElement('link');
      elem.id = 'client-manifest';
      elem.rel = 'manifest';
      elem.href = `./assets/clientes/${cliente}/manifest.webmanifest`;
      head.appendChild(elem);
    }
  }
  private loadColor(color?: string) {
    if (color) {
      const elem = this.document.getElementById(
        'client-color',
      ) as HTMLMetaElement;
      if (elem) {
        elem.content = color;
      } else {
        const head = this.document.getElementsByTagName('head')[0];
        const elem = this.document.createElement('meta');
        elem.id = 'client-color';
        elem.name = 'theme-color';
        elem.content = color;
        head.appendChild(elem);
      }
    }
  }
  private loadTitle(cliente?: string) {
    if (cliente) {
      cliente = cliente.split('_').join(' ').toUpperCase();
      const elem = this.document.getElementById(
        'client-title',
      ) as HTMLTitleElement;
      if (elem) {
        elem.text = `Alerta ${cliente}`;
      } else {
        const head = this.document.getElementsByTagName('head')[0];
        const elem = this.document.createElement('title');
        elem.id = 'client-title';
        elem.text = `Alerta ${cliente}`;
        head.appendChild(elem);
      }
    }
  }
  private loadIcon(iconUrl?: string) {
    if (iconUrl) {
      const elem = this.document.getElementById(
        'client-icon',
      ) as HTMLLinkElement;
      if (elem) {
        elem.href = iconUrl;
      } else {
        const head = this.document.getElementsByTagName('head')[0];
        const elem = this.document.createElement('link');
        elem.id = 'client-icon';
        elem.href = iconUrl;
        head.appendChild(elem);
      }
    } else {
      console.log('No se ha definido el icono');
    }
  }
  private loadAppleIcon(iconUrl?: string) {
    if (iconUrl) {
      const elem = this.document.getElementById(
        'client-icon-apple',
      ) as HTMLLinkElement;
      if (elem) {
        elem.href = iconUrl;
      } else {
        const head = this.document.getElementsByTagName('head')[0];
        const elem = this.document.createElement('link');
        elem.id = 'client-icon-apple';
        elem.href = iconUrl;
        head.appendChild(elem);
      }
    } else {
      console.log('No se ha definido el icono');
    }
  }

  private loadMapsKey(platform: 'web' | 'android' | 'ios') {
    let url = 'https://maps.googleapis.com/maps/api/js?key={MAPS_KEY}';
    switch (platform) {
      case 'web': {
        url = url.replace('{MAPS_KEY}', MAPS_KEY_WEB);
        break;
      }
      case 'android': {
        url = url.replace('{MAPS_KEY}', MAPS_KEY_ANDROID);
        break;
      }
      case 'ios': {
        url = url.replace('{MAPS_KEY}', MAPS_KEY_IOS);
        break;
      }
    }

    const elem = this.document.getElementById(
      'client-maps-key',
    ) as HTMLScriptElement;
    if (elem) {
      elem.src = url;
    } else {
      const head = this.document.getElementsByTagName('head')[0];
      const elem = this.document.createElement('script');
      elem.id = 'client-maps-key';
      elem.src = url;
      head.appendChild(elem);
    }
  }

  public loadCliente(
    cliente: string,
    platform: 'web' | 'android' | 'ios',
    color?: string,
    iconUrl?: string,
  ) {
    if (cliente) {
      console.log(
        'loadCliente',
        cliente,
        'color',
        color,
        'icono',
        iconUrl,
        'platform',
        platform,
      );
      this.loadManifest(cliente);
      this.loadStyle(cliente);
      this.loadColor(color);
      this.loadTitle(cliente);
      this.loadIcon(iconUrl);
      this.loadAppleIcon(iconUrl);
      // this.loadMapsKey(platform);
    } else {
      console.warn('No se ha definido el cliente');
    }
  }
}
