import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatPaginatorModule,
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { IQueryParam } from 'modelos/src';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
  ],
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() saveName?: string;
  @Input() selectorPaginationSize?: boolean;
  private name?: string;

  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;
  goTo: number = 0;
  pageNumbers?: number[];
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @Input() disabled = false;
  @Input() hidePageSize = false;
  @Input() pageSizeOptions: number[] = [];
  @Input() showFirstLastButtons = false;
  @Input('pageIndex') set pageIndexChanged(pageIndex: number) {
    this.pageIndex = pageIndex;
  }
  @Input('length') set lengthChanged(length: number) {
    this.length = length;
    this.updateGoto();
  }

  @Input() public query?: IQueryParam;
  @Output() public queryChange = new EventEmitter<IQueryParam>();

  constructor() {}

  private getSavedPageSize(): number | void {
    if (this.name) {
      const saved = localStorage.getItem(this.name);
      if (saved) {
        return +saved;
      }
    }
    return;
  }
  private savePageSize() {
    if (this.name) {
      localStorage.setItem(this.name, this.pageSize.toString());
    }
  }

  public paginationChange(pageEvt: PageEvent) {
    this.length = pageEvt.length;
    this.pageIndex = pageEvt.pageIndex;
    this.pageSize = pageEvt.pageSize;
    this.savePageSize();
    this.updateGoto();
    this.emitQueryChange(pageEvt);
  }

  public goToChange() {
    this.paginator!.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      length: this.paginator!.length,
      pageIndex: this.paginator!.pageIndex,
      pageSize: this.paginator!.pageSize,
    };
    this.paginator!.page.next(event);
    this.emitQueryChange(event);
  }

  private updateGoto() {
    this.goTo = (this.pageIndex || 0) + 1;
    this.pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.length / this.pageSize); i++) {
      this.pageNumbers.push(i);
    }
  }

  private async emitQueryChange(event: PageEvent): Promise<void> {
    this.query = this.query || {};
    this.query.page = event.pageIndex;
    this.query.limit = event.pageSize;
    this.queryChange.emit(this.query);
  }

  ngOnInit() {
    this.name = this.saveName ? `page-${this.saveName}` : undefined;
    this.pageSize = this.getSavedPageSize() || this.pageSize;
    this.updateGoto();
  }
}
