import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  selector: 'app-texto-largo',
  templateUrl: './texto-largo.component.html',
  styleUrls: ['./texto-largo.component.scss'],
})
export class TextoLargoComponent implements OnInit {
  @Input() label?: string;
  @Input() form?: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
