<div class="scanner-hide" style="height: 100%">
  <router-outlet></router-outlet>
</div>

<div class="scanner-ui">
  <p
    style="
      position: fixed;
      top: 0;
      width: 100%;
      margin-top: 1em;
      background-color: rgba(221, 224, 55, 0.26);
      color: white;
      text-align: center;
      display: block;
      font-size: 20px;
    "
  >
    Centrá el QR en la pantalla
  </p>
  <div
    style="
      width: 100%;
      position: fixed;
      bottom: 0;
      margin-bottom: 1em;
      text-align: center;
      display: block;
    "
  >
    <button mat-fab (click)="atrasDom()" color="primary">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
</div>
