import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ColumnSelectModule } from './column-select/column-select.module';
import { FiltroTablaModule } from './filtro-tabla/filtro-tabla.module';
import { MaterialModule } from './material.module';
import { PermisosModule } from './permisos/permisos.module';

import { BotonModule } from './boton/module';
import { DireccionModule } from './direccion/module';
import { CarruselModule } from './carrusel/carrusel.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TablaComponent } from './tabla/tabla.component';
import { BooleanoComponent } from '../entidades/eventos/formulario/no-template/booleano/booleano.component';
import { FechaComponent } from '../entidades/eventos/formulario/no-template/fecha/fecha.component';
import { NumeroComponent } from '../entidades/eventos/formulario/no-template/numero/numero.component';
import { PersonaComponent } from '../entidades/eventos/formulario/no-template/persona/persona.component';
import { SubirImagenComponent } from '../entidades/eventos/formulario/no-template/subir-imagen/subir-imagen.component';
import { TextoComponent } from '../entidades/eventos/formulario/no-template/texto/texto.component';
import { TextoLargoComponent } from '../entidades/eventos/formulario/no-template/texto-largo/texto-largo.component';
import { UbicacionComponent } from '../entidades/eventos/formulario/no-template/ubicacion/ubicacion.component';

@NgModule({
  imports: [
    CommonModule,
    TablaComponent,
    BooleanoComponent,
    FechaComponent,
    NumeroComponent,
    PersonaComponent,
    SubirImagenComponent,
    TextoComponent,
    TextoLargoComponent,
    UbicacionComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    RouterModule,
    DragDropModule,
    FlexLayoutModule,
    //
    MaterialModule,
    ColumnSelectModule,
    DireccionModule,
    // DialogModule,
    FiltroTablaModule,
    PermisosModule,
    BotonModule,
    CarruselModule,
    NgSelectModule,
    //
    // Componentes
    TablaComponent,
    // Formulario dinamico
    BooleanoComponent,
    FechaComponent,
    NumeroComponent,
    PersonaComponent,
    SubirImagenComponent,
    TextoComponent,
    TextoLargoComponent,
    UbicacionComponent,
  ],
})
export class AuxiliaresModule {}
