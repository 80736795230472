import { Injectable } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { Geometry, LineString, Point } from 'ol/geom';
import { getLength } from 'ol/sphere';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, transform } from 'ol/proj';
import { OSM, Vector, BingMaps } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import { MouseWheelZoom, Select } from 'ol/interaction';
import { defaults as defaultInteractions } from 'ol/interaction';
import CircleStyle from 'ol/style/Circle';
import { GeolocationService } from './geolocation.service';
import { Feature } from 'ol';
import { ICoordenadas } from 'modelos/src';

@Injectable({ providedIn: 'root' })
export class OpenLayersService {
  public static mapTile() {
    return new TileLayer({
      // source: new BingMaps({
      //   imagerySet: 'AerialWithLabels',
      //   key: 'AqQ9Z9Z9Z',
      // }),
      source: new OSM(),
    });
  }

  public static drawVectorLayer() {
    return new VectorLayer({
      source: new VectorSource({ wrapX: false }),
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      }),
    });
  }

  public static styles = {
    Point: {
      'circle-radius': 5,
      'circle-fill-color': 'red',
    },
    LineString: {
      'circle-radius': 4,
      'circle-fill-color': 'red',
      'stroke-color': 'yellow',
      'stroke-width': 3,
    },
    Polygon: {
      'circle-radius': 5,
      'circle-fill-color': 'red',
      'stroke-color': 'yellow',
      'stroke-width': 2,
      'fill-color': 'blue',
    },
    Circle: {
      'circle-radius': 5,
      'circle-fill-color': 'red',
      'stroke-color': 'blue',
      'stroke-width': 2,
      'fill-color': 'yellow',
    },
  };

  public static colectivosVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/map/bus.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static colectivosCompassVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/maps/navigation.png',
          height: 40,
          width: 40,
          rotation: 0,
        }),
      }),
    });
  }

  public static colectivosPinVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/colectivo.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static paradasVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/parada.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static pinVacioVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/pin-blanco.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static paradasSelectedStyle() {
    return new Style({
      image: new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        anchor: [0.5, 1],
        src: 'assets/pins/parada-selected.png',
        height: 35,
        width: 35,
      }),
    });
  }

  public static paradasSelectEvent() {
    return new Select({
      style: OpenLayersService.paradasSelectedStyle(),
      filter: (feature) => {
        return feature.get('parada') === true;
      },
    });
  }

  public static paradasTextVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        text: new Text({
          fill: new Fill({ color: 'black' }),
        }),
      }),
    });
  }

  public static polylineVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 4,
        }),
      }),
    });
  }

  public static personVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/person.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static homeVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/home.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static circleVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      }),
    });
  }

  public static sirenaAzulVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/pins/azul.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static sirenaRojaVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/pins/roja.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static sirenaVerdeVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/pins/verde.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static sirenaAmarillaVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/pins/amarilla.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static pinVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/pin.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static styleFunctionArrow(feature: Feature<Geometry>) {
    const geometry = feature.getGeometry() as LineString;
    const oldStyles = feature.getStyle() as Style;
    const stroke = oldStyles?.getStroke();
    const styles = [
      // linestring
      new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2,
          lineDash: stroke?.getLineDash() ? [4, 8] : undefined,
        }),
      }),
    ];

    geometry?.forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(
        new Style({
          geometry: new Point(end),
          image: new Icon({
            src: 'assets/map/arrow.png',
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
          }),
        }),
      );
    });

    return styles;
  }

  //// TRIPS ////

  public static stylesTrip = {
    route: new Style({
      stroke: new Stroke({
        width: 6,
        color: [237, 212, 0, 0.8],
        lineDash: [4, 8],
      }),
    }),
    start: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: 'assets/pins/start.png',
        width: 30,
        height: 30,
      }),
    }),
    end: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: 'assets/pins/end.png',
        width: 30,
        height: 30,
      }),
    }),
    geoMarker: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
      }),
    }),
    // geoMarker: new Style({
    //   image: new Icon({
    //     anchor: [0.5, 1],
    //     src: 'assets/pins/colectivo.png',
    //     width: 30,
    //     height: 30,
    //   }),
    // }),
  };

  public static geoMarkerLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/colectivo.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static startTripLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/start.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static endTripLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          anchor: [0.5, 1],
          src: 'assets/pins/end.png',
          height: 40,
          width: 40,
        }),
      }),
    });
  }

  public static wheelInteraction() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const f = (e: any) => {
      if (e.type === 'wheel' && e.originalEvent.ctrlKey) {
        return true;
      }
      return false;
    };

    const mouseWheelOptions = new MouseWheelZoom({
      condition: f,
    });

    return mouseWheelOptions;
  }

  public static interactions() {
    return defaultInteractions({
      mouseWheelZoom: false,
      doubleClickZoom: false,
    }).extend([OpenLayersService.wheelInteraction()]);
  }

  constructor(private geolocationService: GeolocationService) {}

  public static async getCurrentPosition(): Promise<Coordinate> {
    return new Promise((resolve) => {
      const ubicacionBase = OpenLayersService.lonLatToCoordinate(
        -58.0128784,
        -35.5836812,
      );
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion = OpenLayersService.lonLatToCoordinate(
              position.coords.longitude,
              position.coords.latitude,
            );
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  public async getCurrentPositionCapacitor(): Promise<Coordinate> {
    const ubicacionBase = OpenLayersService.lonLatToCoordinate(
      -58.0128784,
      -35.5836812,
    );
    const coord = await this.geolocationService.getCurrentPosition();
    if (coord) {
      return OpenLayersService.lonLatToCoordinate(coord.lng, coord.lat);
    }
    return ubicacionBase;
  }

  public static lonLatToCoordinate(lon: number, lat: number): Coordinate {
    if (!lon || !lat) return;
    const coord = fromLonLat([lon, lat]);
    return coord;
  }

  public static lonLatToCoordinates(coords: ICoordenadas[]) {
    if (!coords) return [];
    return coords.map((c) =>
      OpenLayersService.lonLatToCoordinate(c.lng, c.lat),
    );
  }

  public static distanceBetweenPoints(p1: Coordinate, p2: Coordinate): number {
    return getLength(new LineString([p1, p2]));
  }

  public static leastDistanceFromLineString(c: Coordinate, line: Coordinate[]) {
    const ls = new LineString(line);
    const cp = ls.getClosestPoint(c);
    const distance = OpenLayersService.distanceBetweenPoints(c, cp);
    return distance;
  }

  public static cordinateToGeoJSONPoint(coord: Coordinate): {
    type: 'Point';
    coordinates: [number, number];
  } {
    coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
    return {
      type: 'Point',
      coordinates: [coord[0], coord[1]],
    };
  }

  public static cordinatesToGeoJSONLineString(coords: Coordinate[]): {
    type: 'LineString';
    coordinates: [number, number][];
  } {
    const coordinates: [number, number][] = coords.map((coord) => {
      coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
      return [coord[0], coord[1]];
    });
    return {
      type: 'LineString',
      coordinates,
    };
  }

  public static coordinateToCoordenada(coord: Coordinate): ICoordenadas {
    coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
    return { lng: coord[0], lat: coord[1] };
  }
}
