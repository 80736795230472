import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { DialogService } from './dialog.service';
import { PromptComponent } from './prompt/prompt.component';
import { FormsModule } from '@angular/forms';
import { DialogComponent } from './dialog/dialog.component';
import { MaterialModule } from '../material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, DragDropModule],
  declarations: [ConfirmationComponent, PromptComponent, DialogComponent],
  providers: [DialogService],
})
export class DialogModule {}
