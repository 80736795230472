import { Injectable } from '@angular/core';
import {
  ICreateReclamo,
  IEstadoReclamo,
  IListado,
  IQueryParam,
  IReclamo,
  IUpdateReclamo,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ReclamosService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IReclamo>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/reclamos`, false, params);
  }

  public listarPorId(id: string): Observable<IReclamo> {
    return this.http.get(`/reclamos/${id}`);
  }

  public listarEstadosPorId(id: string): Observable<IListado<IEstadoReclamo>> {
    return this.http.get(`/estadoReclamos/idReclamo/${id}`);
  }

  public crear(dato: ICreateReclamo): Observable<IReclamo> {
    return this.http.post(`/reclamos`, dato);
  }

  public update(id: string, dato: IUpdateReclamo): Observable<IReclamo> {
    return this.http.put(`/reclamos/${id}`, dato);
  }

  public eliminar(id: string): Observable<IReclamo> {
    return this.http.delete(`/reclamos/${id}`);
  }

  public subirImagen(
    file: File,
    nombreImagen: string,
  ): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', nombreImagen);

    return this.http.post(`/storage/upload`, formData);
  }
}
