import { Injectable } from '@angular/core';
import { ICliente, IConfigVecino, IToken, IVecino } from 'modelos/src';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public async isLogged() {
    const token = await this.getToken();
    return token ? true : false;
  }

  private async refreshFromLocalStorage() {
    const accessToken = this.getAccessTokenLocalStorage();
    const refreshToken = this.getRefreshTokenLocalStorage();
    const vecino = this.getVecinoLocalStorage();

    if (!accessToken || !vecino) {
      return;
    }

    const token = {
      accessToken,
      refreshToken,
      user: vecino,
    } as IToken;

    await this.setTokenPreferences(token);
    return token;
  }

  private async getTokenCompleto(): Promise<IToken | void> {
    return await this.getTokenPreferences();
  }

  public async getToken(): Promise<string | void> {
    const token = await this.getTokenCompleto();
    if (!token) {
      const token2 = await this.refreshFromLocalStorage();
      if (!token2) {
        return;
      }
      return token2.accessToken;
    }

    return token.accessToken;
  }

  public async getRefreshToken(): Promise<string | void> {
    const token = await this.getTokenCompleto();
    if (!token) {
      const token2 = await this.refreshFromLocalStorage();
      if (!token2) {
        return;
      }
      return token2.refreshToken;
    }

    return token.refreshToken;
  }

  public async getVecino(): Promise<IVecino | undefined> {
    const token = await this.getTokenCompleto();
    if (!token) {
      const token2 = await this.refreshFromLocalStorage();
      if (!token2) {
        return;
      }
      return token2.user as IVecino;
    }

    return token.user as IVecino;
  }

  public async removeLoginInfo() {
    await Promise.all([
      this.removeTokenPreferences(),
      this.removeVecinoPreferences(),
      this.removeConfigPreferences(),
    ]);
    this.removeTokenLocalStorage();
    this.removeVecinoLocalStorage();
    this.removeConfigLocalStorage();
  }

  public async setVecino(datos: IVecino) {
    await this.updateVecinoPreferences(datos);
  }

  public async setConfig(datos: IConfigVecino) {
    await Preferences.set({
      key: 'config',
      value: JSON.stringify(datos),
    });
  }

  public async getConfig() {
    const res = await Preferences.get({ key: 'config' });
    if (!res.value) {
      return;
    }
    return JSON.parse(res.value) as IConfigVecino;
  }

  public async setPrimeraVez(b: boolean) {
    await Preferences.set({
      key: 'primeraVez',
      value: JSON.stringify(b),
    });
  }

  public async getPrimeravez() {
    const res = await Preferences.get({ key: 'primeraVez' });
    if (res.value === 'false') {
      return false;
    } else {
      return true;
    }
  }

  public async setCliente(cliente: ICliente) {
    await Preferences.set({
      key: 'cliente',
      value: JSON.stringify(cliente),
    });
  }

  public async getCliente() {
    const res = await Preferences.get({ key: 'cliente' });
    if (!res.value) {
      return;
    }
    return JSON.parse(res.value) as ICliente;
  }

  public async setLoginInfo(token: IToken) {
    await this.setTokenPreferences(token);
  }

  //

  public async setValue(key: string, value: string) {
    await Preferences.set({
      key,
      value,
    });
  }

  public async getValue(key: string) {
    const res = await Preferences.get({ key });
    if (!res.value) {
      return;
    }
    return res.value;
  }

  public async removeValue(key: string) {
    await Preferences.remove({ key });
  }

  // Private
  private async setTokenPreferences(token: IToken) {
    await Preferences.set({
      key: 'token',
      value: JSON.stringify(token),
    });
  }
  private async getTokenPreferences(): Promise<IToken | void> {
    const ret = await Preferences.get({ key: 'token' });
    if (!ret.value) {
      return;
    }
    return JSON.parse(ret.value);
  }
  private async updateVecinoPreferences(vecino: IVecino) {
    const token = await this.getTokenPreferences();
    if (!token) {
      return;
    }

    token.user = vecino;
    await this.setTokenPreferences(token);
  }

  private async removeTokenPreferences() {
    await Preferences.remove({ key: 'token' });
  }
  private async removeVecinoPreferences() {
    await Preferences.remove({ key: 'vecino' });
  }
  private async removeConfigPreferences() {
    await Preferences.remove({ key: 'config' });
  }

  // Private local storage
  private getVecinoLocalStorage(): IVecino | void {
    const vecino = localStorage.getItem('vecino');
    if (!vecino) {
      return;
    }

    return JSON.parse(vecino);
  }

  private getAccessTokenLocalStorage(): string | void {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    return token;
  }

  private getRefreshTokenLocalStorage(): string | void {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return;
    }

    return refreshToken;
  }

  private removeTokenLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('vecino');
    localStorage.removeItem('config');
  }
  private removeVecinoLocalStorage() {
    localStorage.removeItem('vecino');
  }
  private removeConfigLocalStorage() {
    localStorage.removeItem('config');
  }
}
