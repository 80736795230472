import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICategoria,
  IConfigVecino,
  IContacto,
  IQueryParam,
  IVecino,
} from 'modelos/src';
import { ErroresClienteService } from './errores-cliente.service';
import { DialogService } from '../dialog/dialog.service';
import { Camera, CameraResultType } from '@capacitor/camera';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Capacitor } from '@capacitor/core';
import { NOMBRE_APP } from '../../../environments/cliente';
import tinycolor from 'tinycolor2';
import { MatDialog } from '@angular/material/dialog';
import { MostradorComponent } from '../mostrador/mostrador.component';

export type OS = 'Windows Phone' | 'Android' | 'iOS' | 'unknown';
export type Platform = 'web' | 'android' | 'ios';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public pageSize(name: string) {
    if (name) {
      const pageName = `page-${name}`;
      const saved = localStorage.getItem(pageName);
      if (saved) {
        return +saved;
      }
    }
    return 10;
  }
  public pageSizeOptions = [5, 10, 15, 25, 50, 100];

  public static platform = Capacitor.getPlatform() as Platform;
  public static os = HelperService.getMobileOperatingSystem();

  public regTel = /^(?!0|15)\d{10}$/;
  public regDni = /^([MFmf]\d{7}|\d{7,9})$/;
  public regEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  public phoneCodes = [
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: '---------------',
      dial_code: '',
      code: 'AR',
      disabled: true,
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      code: 'BR',
    },
    {
      name: 'Chile',
      dial_code: '+56',
      code: 'CL',
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      code: 'CO',
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      code: 'EC',
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      code: 'MX',
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      code: 'PY',
    },
    {
      name: 'Peru',
      dial_code: '+51',
      code: 'PE',
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      code: 'UY',
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
    },
    {
      name: 'Venezuela, Bolivarian Republic of',
      dial_code: '+58',
      code: 'VE',
    },
  ];

  constructor(
    private erroresClienteService: ErroresClienteService,
    private dialogService: DialogService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  static nombreClienteToClienteFilename(nombreCliente?: string): string {
    if (NOMBRE_APP === 'Barrios Privados') {
      return 'barrios_privados';
    }
    if (nombreCliente) {
      const fileName = nombreCliente
        ? nombreCliente
            .split(' ')
            .join('_')
            .split('á')
            .join('a')
            .split('é')
            .join('e')
            .split('í')
            .join('i')
            .split('ó')
            .join('o')
            .split('ú')
            .join('u')
            .split('Á')
            .join('A')
            .split('É')
            .join('E')
            .split('Í')
            .join('I')
            .split('Ó')
            .join('O')
            .split('Ú')
            .join('U')
            .toLowerCase()
        : '';
      return fileName;
    }
    return 'admin';
  }

  public telValido(telefono?: string) {
    return this.regTel.test(telefono || '');
  }

  public emailValido(email?: string) {
    return this.regEmail.test(email || '');
  }

  public dniValido(dni?: string) {
    return this.regDni.test(dni || '');
  }

  static creoContacto(c: IContacto, config: IConfigVecino) {
    return c.idVecino === config.idVecino;
  }

  // Notif

  public getQueryParams(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
      ];
      if (queryParams?.page) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  public pageEvent($event: any): void {
    localStorage.setItem('pageSize', $event.pageSize);
    this.pageSize = $event.pageSize;
  }

  public async enviarError(error: any): Promise<void> {
    await this.erroresClienteService.crear(error);
  }

  public async notifError(error: any, enviarError = false): Promise<void> {
    if (typeof error === 'string') {
      this.snackBar.open(error, 'Cerrar', { panelClass: ['red-snackbar'] });
      // this.notificationsService.error(error);
    } else {
      const defaultMsg = 'Ha ocurrido un error inesperado';
      const mensaje =
        error?.error?.mensaje ||
        error?.error?.message ||
        error?.mensaje ||
        error?.message ||
        defaultMsg;
      console.error(error);
      this.snackBar.open(mensaje, 'Cerrar', { panelClass: ['red-snackbar'] });
      // this.notificationsService.error(mensaje);
      if (!enviarError && mensaje === defaultMsg) {
        await this.enviarError(error);
      }
    }
    if (enviarError) {
      await this.enviarError(error);
    }
  }
  public notifSuccess(mensaje: string): void {
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 3000,
      panelClass: ['green-snackbar'],
    });
    // this.notificationsService.success(mensaje);
  }
  public notifWarn(mensaje: string): void {
    // this.notificationsService.warn(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', { panelClass: ['yellow-snackbar'] });
  }

  public mostrarAlgo(datos: string): void {
    this.dialog.open(MostradorComponent, {
      data: datos,
      maxWidth: '90vw',
      maxHeight: '90vh',
    });
  }

  public getCategoriaVecino(config: IConfigVecino): ICategoria {
    if (config.categoria?.categoria && config.categoria?.hasta) {
      const timeNow = new Date();
      const timeCategoria = new Date(config.categoria.hasta);
      if (timeNow < timeCategoria) {
        return config.categoria.categoria;
      }
    }
    if (config.cliente?.categoriaDefault) {
      return config.cliente.categoriaDefault;
    }
    throw new Error('No se ha encontrado una categoría para el vecino');
  }

  private padZero(str: string, len: number = 2): string {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
  public invertColor(hex?: string, bw: boolean = true) {
    hex = tinycolor(hex).toHexString();
    if (!hex) {
      return '#000000';
    }
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    const r_str = (255 - r).toString(16);
    const g_str = (255 - g).toString(16);
    const b_str = (255 - b).toString(16);
    // pad each with zeros and return
    return (
      '#' + this.padZero(r_str) + this.padZero(g_str) + this.padZero(b_str)
    );
  }

  // Mapa y coordenadas

  public base64ABlob(base: string, mime: string) {
    const byteCharacters = atob(base);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mime });
  }

  //
  public helpDNI() {
    this.dialogService.dialog(
      'DNI Válidos',
      `<p> 
        7 Dígitos: <strong>1234567</strong> <br>
        8 Dígitos: <strong>12345678</strong> <br>
        7 Dígitos con F o M: <strong>F1234567</strong>
      </p>`,
    );
  }

  public setFechaSolicitudCodigo() {
    const date = Date.now();
    localStorage.setItem('fechaSolicitudCodigo', date.toString());
  }

  public getFechaSolicitudCodigo() {
    const date = localStorage.getItem('fechaSolicitudCodigo');
    return date ? parseInt(date) : null;
  }

  // Imagenes

  static convertBase64ToBlob(
    b64Data: string,
    contentType = 'jpeg',
    sliceSize = 512,
  ) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  static async sacarFoto() {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        width: 1280,
        height: 720,
        correctOrientation: false,
      });
      if (image.base64String) {
        return new File(
          [HelperService.convertBase64ToBlob(image.base64String)],
          'image.jpg',
        );
      } else {
        console.log(`No hay foto`);
      }
    } catch (err) {
      console.error(err);
    }
    return;
  }

  static async rotarYAchicar(f: File) {
    const rotado = (await HelperService.rotateImage(f, -90)) as Blob;
    const rotadoFile = new File([rotado], 'uploaded_file.jpg', {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });
    const achicado = (await HelperService.resizeImage(
      rotadoFile,
      1280,
      720,
    )) as Blob;
    const file = new File([achicado], 'uploaded_file.jpg', {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });
    return file;
  }

  static rotateImage(file: Blob, grados: number) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width > height) {
          resolve(file);
        }
        let canvas = document.createElement('canvas');
        canvas.width = height;
        canvas.height = width;

        let ctx = canvas.getContext('2d');
        ctx?.clearRect(0, 0, canvas.width, canvas.height);
        ctx?.save();
        ctx?.translate(canvas.width / 2, canvas.height / 2);
        ctx?.rotate((grados * Math.PI) / 180);
        ctx?.drawImage(image, -image.width / 2, -image.height / 2);
        ctx?.restore();

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

  static resizeImage(file: File, maxWidth: number, maxHeight: number) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        // Chequeo si la imagen es más chica que los límites
        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let canvas = document.createElement('canvas');
        canvas.width = maxWidth;
        canvas.height = maxHeight;

        let context = canvas.getContext('2d');

        context?.drawImage(image, 0, 0, maxWidth, maxHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

  static getBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Chrome') > -1) {
      return 'Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    } else if (userAgent.indexOf('Opera') > -1) {
      return 'Opera';
    } else if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (userAgent.indexOf('MSIE') > -1) {
      return 'IE';
    }
    return 'Desconocido';
  }

  static getMobileOperatingSystem(): OS {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }
}
