import { BrowserModule } from '@angular/platform-browser';
import { Inject, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule,
  DOCUMENT,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

import {
  apiBoton,
  environment,
  websocketServer,
} from '../environments/environment';

// Componentes
import { AppComponent } from './app.component';
import { register } from 'swiper/element/bundle';

// Locale
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getEspPaginatorIntl } from 'src/locale/paginator-intl';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
import { PwaService } from './auxiliares/servicios/pwa.service';
import { HttpClientModule } from '@angular/common/http';
import { DialogModule } from './auxiliares/dialog/dialog.module';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { AuxiliaresModule } from './auxiliares/auxiliares.module';
import { NOMBRE_APP } from '../environments/cliente';
import { UpdatesService } from './auxiliares/servicios/updates.service';

registerLocaleData(localeEs, 'es-AR');
//

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // enabled: true,
      registrationStrategy: 'registerImmediately',
      scope: '/',
    }),
    //
    DialogModule,
    HttpClientModule,
    AppRoutingModule,
    AuxiliaresModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: MatPaginatorIntl, useValue: getEspPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    public pwaService: PwaService,
    @Inject(DOCUMENT) private document: Document,
    private updatesService: UpdatesService,
  ) {
    console.log(`Nombre app: ${NOMBRE_APP}`);
    console.log(`API: ${apiBoton}`);
    console.log(`WS: ${websocketServer}`);

    register();
    SafeArea.getSafeAreaInsets().then((i) => {
      let html = this.document.getElementById('html') as HTMLHtmlElement;
      const height = `calc(100vh - ${i.insets.top + i.insets.bottom}px)`;

      html.style.height = height;
      html.style.maxHeight = height;
    });

    //
    this.updatesService.checkUpdates();
  }
}
