import { v } from './variables';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAWLvLWHQgKKV5PVgakyDqgfPUzXwWZk48',
    authDomain: 'grupo-control-52664.firebaseapp.com',
    projectId: 'grupo-control-52664',
    storageBucket: 'grupo-control-52664.appspot.com',
    messagingSenderId: '719442103684',
    appId: '1:719442103684:web:a6aada7b34506c2d19a1d1',
    measurementId: 'G-RQ0D66RD1R',
    vapidKey:
      'BDX9npKcXc84RfoDVyauDcQi0mDj-jbOwDuwXwWYt41I1nfHKNvk6VmzHbCr1pIhXGq9qPBrS4iKcxmNCG1Lnkk',
  },
};

// INTEGRACIONES EXTERNAS
export const UMA_HOST = 'https://dev.patient.umasalud.com';

export const ENV: 'local' | 'test' | 'prod' | 'dev' = 'test';
export const VERSION = v;
// Apis

export const apiBoton = 'https://apis2.novit.gpesistemas.ar/boton-test';
export const websocketServer =
  'wss://apis2.novit.gpesistemas.ar/websocket-test';
