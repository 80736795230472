import { Injectable } from '@angular/core';
import { apiBoton } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

const api = apiBoton

@Injectable({
  providedIn: 'root'
})
export class HttpApiService {

  constructor(
    private http: HttpService,
  ) { }

  public get(ruta: string, noCache = false, params?: any): Observable<any> {
    return this.http.get(api + ruta, noCache, params);
  }

  public post(ruta: string, body: any): Observable<any> {
    return this.http.post(api + ruta, body);
  }

  public put(ruta: string, body: any): Observable<any> {
    return this.http.put(api + ruta, body);
  }

  public delete(ruta: string): Observable<any> {
    return this.http.delete(api + ruta);
  }

}
