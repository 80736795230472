import { Injectable } from '@angular/core';
import { ICoordenadas } from 'modelos/src';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private platform = Capacitor.getPlatform();
  public permissionState: 'denied' | 'granted' | 'prompt' = 'prompt';
  public hasPermission: boolean = false;

  constructor() {}

  public async checkGPS() {
    // WEB
    if (this.platform === 'web') {
      //
      if (HelperService.getBrowser() === 'Safari') {
        try {
          await this.getCurrentPosition();
          this.permissionState = 'granted';
          this.hasPermission = true;
          return 'granted';
        } catch (error) {
          this.permissionState = 'denied';
          this.hasPermission = false;
          return 'denied';
        }
      }

      const permissionStatus = await navigator?.permissions?.query({
        name: 'geolocation',
      });
      if (permissionStatus.state === 'granted') {
        this.permissionState = 'granted';
      } else if (permissionStatus.state === 'denied') {
        this.permissionState = 'denied';
      }
      this.hasPermission = permissionStatus?.state === 'granted';
    }

    // Capacitor
    if (this.platform === 'android' || this.platform === 'ios') {
      const check = (await Geolocation.checkPermissions()).coarseLocation;
      const check2 = (await Geolocation.checkPermissions()).location;

      if (check === 'granted' || check2 === 'granted') {
        this.permissionState = 'granted';
      } else if (check === 'denied') {
        this.permissionState = 'denied';
      }

      this.hasPermission = check === 'granted';
    }

    return this.permissionState;
  }

  public async getCurrentPosition(): Promise<ICoordenadas> {
    return new Promise(async (resolve, reject) => {
      if (HelperService.getBrowser() !== 'Safari') {
        await this.checkGPS();
        if (this.permissionState === 'denied') {
          reject({
            message:
              'Debe otorgar permisos de ubicación desde las opciones del sistema',
          });
        }
      }

      // WEB
      if (this.platform === 'web') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const ubicacion: ICoordenadas = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              resolve(ubicacion);
            },
            (error) => {
              console.error(
                `No se ha podido obtener la ubicación, verifica los permisos de ubicación`
              );

              reject({
                message:
                  'No se ha podido obtener la ubicación, verifica los permisos de ubicación',
              });
            },
            { enableHighAccuracy: true }
          );
        } else {
          reject({
            mensaje: 'El sistema no soporta acceso a la ubicación',
          });
        }
      }

      // Capacitor
      if (this.platform === 'android' || this.platform === 'ios') {
        await Geolocation.requestPermissions();
        const status = await Geolocation.checkPermissions();

        if (
          status.coarseLocation === 'granted' ||
          status.location === 'granted'
        ) {
          const pos = await Geolocation.getCurrentPosition();
          const ubicacion: ICoordenadas = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          resolve(ubicacion);
        } else {
          reject({
            mensaje: 'No hay permisos para la obtener la ubicación',
          });
        }
      }
    });
  }
}
