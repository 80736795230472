<h3 style="font-size: 18px">{{ label }}</h3>

<ul>
  <ng-container *ngFor="let p of personas">
    <li>{{ p.nombre }} - {{ p.dni }}</li>
  </ng-container>
</ul>

<form *ngIf="!individuo">
  <mat-form-field>
    <mat-label>Nombre</mat-label>
    <input
      matInput
      [(ngModel)]="nombre"
      [ngModelOptions]="{ standalone: true }"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>DNI</mat-label>
    <input matInput [(ngModel)]="dni" [ngModelOptions]="{ standalone: true }" />
  </mat-form-field>
  <div class="row-center">
    <button mat-raised-button color="primary" (click)="agregarPersona()">
      Agregar Persona
    </button>
  </div>
</form>

<form *ngIf="individuo">
  <mat-form-field>
    <mat-label>Nombre</mat-label>
    <input
      matInput
      [(ngModel)]="nombre"
      [ngModelOptions]="{ standalone: true }"
      (keyup)="onChange()"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>DNI</mat-label>
    <input
      matInput
      [(ngModel)]="dni"
      [ngModelOptions]="{ standalone: true }"
      (keyup)="onChange()"
    />
  </mat-form-field>
</form>
