import { Component, Inject } from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mostrador',
  standalone: true,
  imports: [AuxiliaresModule, CommonModule, MaterialModule],
  templateUrl: './mostrador.component.html',
  styleUrl: './mostrador.component.scss',
})
export class MostradorComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MostradorComponent>,
  ) {}

  public onClose() {
    this.dialogRef.close();
  }
}
